import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; 
import Lpimage from "../../images/clientLogos/languagePantheon.png"
import Gradeup from "../../images/clientLogos/gradeup.png"
import Iamsnext from "../../images/clientLogos/iamsnext.svg"
import Uniqueshiksha from "../../images/clientLogos/uniqueshiksha.png"
import Kiranprakashan from "../../images/clientLogos/bookstreeLogo.png"
import GovtMinistry from "../../images/clientLogos/ministrylogo.png"
import Igp from "../../images/clientLogos/igp.png"
import Bajajfinserv from "../../images/clientLogos/Bajaj-Finserv.png"
import Proptiger from "../../images/clientLogos/proptiger.png"
import Dbmi from "../../images/clientLogos/dbmi.png"
import Housing from "../../images/clientLogos/housing.png"
// import Igiaviation from "../../images/clientLogos/igiaviationdelhi.png"

const Container = styled.ul`
  ${tw`relative`}
  li{
    ${tw`xs:w-2/5`}
  }

`;


export default ({
  clientLogos = [
      {
        logoSrc: Proptiger,
        clientName: "proptiger",
        increaseSize:false,
      },
      {
        logoSrc: Housing,
        clientName: "housing.com",
        increaseSize:false,
      },
      {
        logoSrc: Bajajfinserv,
        clientName: "Bajaj Finserv",
        increaseSize:false,
      },
      
      {
        logoSrc: Kiranprakashan,
        clientName: "Kiranprakashan",
        increaseSize:false,
      },
      {
        logoSrc: Lpimage,
        clientName: "Language Pantheon",
        increaseSize:false,
      },
      {
        logoSrc: Igp,
        clientName: "IGP Institute",
        increaseSize:false,
      },
      {
        logoSrc: Iamsnext,
        clientName: "IAMNEXT",
        increaseSize:false,
      },
      {
        logoSrc: Uniqueshiksha,
        clientName: "Unique Shiksha",
        increaseSize:false,
      },
      {
        logoSrc: Dbmi,
        clientName: "DBMI",
        increaseSize:false,
      },
      {
        logoSrc: GovtMinistry,
        clientName: "Govt. Ministry",
        increaseSize:true,
      },
    ]

}) => {
  

  return (
    <Container className="wr-clients">
        {clientLogos.map((item, index) => (
      <li>
        <img src={item.logoSrc} alt={item.clientName}  style={item.increaseSize==true?{height:'auto'}:{width:'auto'}}/>
      </li>
    ))} 
    </Container>
  );
};
