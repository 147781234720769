import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import prefooterImage from "../../images/supportEventImage.png"

const Container = styled.div`
  ${tw`relative bg-brand-100 text-gray-100 -mx-8 px-8 py-10 xs:px-12`}
  background: rgb(254,65,77);
  background: linear-gradient(7deg, rgba(254,65,77,1) 0%, rgba(254,65,77,1) 24%, rgba(254,65,77,1) 100%);
`;
const Content = tw.div`max-w-screen-xl mx-auto pt-5 pb-5`
const TwoColumns = tw.div`flex flex-wrap justify-center`;

const Column = tw.div`sm:w-full md:w-2/3 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;

const ColumnHeading = tw.h2`text-white`;
const Subheading = tw(SubheadingBase)`mb-4 text-white text-base font-normal`;
export default ({
  formheading = "Ready to create a product together?", 
  // formsubheading = "Book Our Free Discussion Session Today -",
  heightlightText = "Discuss with us and we'll guide you the way forward.",
  fromPage = "",
  wappAllow = true,
  formActionUrl = "",
}) => {
  return (
    <Container>
      <Content>
        <TwoColumns>
          <Column>
            <ColumnHeading>{formheading}</ColumnHeading>
            {/*<ColumnHeading>&nbsp;</ColumnHeading>*/}
            <Subheading><strong>{heightlightText}</strong></Subheading>
            <ContactUsForm fromPage={fromPage} wapp={wappAllow} formActionUrl = {formActionUrl} />
          </Column>
          {/*<Column>
            <img src={prefooterImage} />
          </Column>*/}
        </TwoColumns>
      </Content>
    </Container>
  );
};
