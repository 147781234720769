import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainBanner from "components/hero/AurumHeroContent.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures.js";
import Features from "components/features/ThreeColWithSideImage.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import { Helmet } from 'react-helmet';
const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-brand-500`;
const HighlightedText = tw.span`text-brand-500 `;

const BoldTag = styled.span`
   ${tw`text-textBlack font-bold`}
`;
export default ({
 title = "Simplified On-Boarding",
 highlightedtext = "Get Started.",
 heading = "Easy to",
 CheckIcon = Checkicon,
}) => { 
  return (
     <>
    <Helmet
      
      title='Why Aurum Labs ? Learn Why Aurum Labs is Best for Content Delivery'
      
      meta={[
        {
          name: 'description',
          content: 'Aurum Labs is the All in One Platform for Content Delivery and Monetisation',
        },
        {
          property: `og:title`,
          content: 'Aurum Labs is the All in One Platform for Content Delivery and Monetisation',
        },
        {
          property: `og:description`,
          content: 'Aurum Labs is the All in One Platform for Content Delivery and Monetisation',
        }]}
    />
    <AnimationRevealPage>
      <MainBanner roundedHeaderButton={true} 
        mainTitle="The All in One Content Platform - Host, Deliver & Monetise" 
        subTitle="Everything Needed to Organise, Streamline and Grow Your Content Business - All in One Place."
        buttonText="Let's Talk"
        buttonUrl="https://wa.me/918826622806/?text=Hi, I would like to GROW my Business with Aurum Content Delivery Platform."
        imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/why_aurum_main.gif"
        showClient= {false}
        />
      <OurExpertise
       description = {
        <>
          <b>Use Single Platform</b><p>Replace the Use of Multiple Staggered SAAS platforms for Video On Demand, Live Streaming, APIs or PDF sharing. Make Your Life Simple. Focus on Business, Realise Your Aspirations.</p><br />
          <b>Build Customised Apps</b><p>We understand every Content Business needs a different Content Framework for Users. Aurum Apps enables you to build Your Brand and Business, in Quickest Time and Minimum Possible Costs.</p><br />
          <b>Make Your Brand Identity</b><p>Deliver the best digital experiences with Aurum Labs, engage and monetise your users, grow your business, establish a brand presence in the market.</p><br />
          <b>Easy to Use APIs</b><p>Easily host and stream live videos, events and video on demand, with agility and security.</p><br />
          <b>Save on Costs</b><p>We are focussed to deliver services at the most afforable costs in the industry. Connect today to know how u can save upto 40% compared to other platforms.</p><br />

        </>
      }
        subheading = {
          <>
          Why Aurum Labs ??
          </>
        }
        heading = {
          <>
            One Stop Platform for Content Business - <span tw="text-brand-500">Apps, APIs and Much More</span>
          </>
        }
        features = {"NO"}
        imageSrc = "https://ik.imagekit.io/zjste1wkulv/tr:q-90/aurum_for_business.gif"
      />
      <DashedBorderSixFeatures
      />
      <Testimonials description = {
        <>
          We work with you from start to finish. We view ourselves as an extension of your team, and as your partner, we pride ourselves in being fully engaged with your company to help you find opportunities for growth. <BoldTag>The Reason why Content Owners Love Aurum</BoldTag> 
        </>
      } imageSrc = "https://ik.imagekit.io/zjste1wkulv/tr:q-90/testimonials2.jpg"
        />
      <PreFooter fromPage = "Content Delivery Platform" formheading = "Streamlining & Managing Content Storage and Distribution Amplifies Team Efficiency and Sales" formsubheading = "Book Our Free Discussion Session Today - " heightlightText="The Benefits of Using a Structured Content Distribution Platform"/>
      <Footer />
    </AnimationRevealPage>
    </>
  );
};
