import React from 'react'
import { Helmet } from 'react-helmet';
import tw from "twin.macro";
import Hero from "components/hero/AurumHeroContent.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import DevelopmentProcess from "components/development-process/";
import PreFooter from "components/footers/PreFooterBrand.js";
import qualityAssuranceHeroImage from 'images/quality-assurance-hero-image.svg'
import qualityAssurance from 'images/quality-assurance.svg'
import Footer from "components/footers/SimpleFiveColumn.js";

const QualityAssurance = () => {
    const Subheading = tw.p`tracking-widest text-textBlack text-center`;
    const HighlightedText = tw.span`text-primary-500`;
    const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
    const TITLE = 'Quality Assurance';
    const tagNameD = `Ensure top-notch product performance with our comprehensive Quality Assurance services. Grow your business with our Test Automation, Integration Testing, System testing.`;


    return (
        <>
            <Helmet
                title={TITLE}
                meta={[
                    {
                        name: 'description',
                        content: { tagNameD },
                    },
                    {
                        property: `og:title`,
                        content: { TITLE },
                    },
                    {
                        property: `og:description`,
                        content: { tagNameD },
                    },
                    {
                        name: 'keywords',
                        content: 'Testing, Test Automation, Regression Testing, Integration Testing, System Testing',
                    }
                ]}
            />
            <Hero roundedHeaderButton={true}
                mainTitle="Quality Assurance"
                subTitle={tagNameD}
                buttonText="Lets Go"
                buttonUrl="https://wat.me/919971200884/?text=Hi, We would like develop our App"
                imageSrc={qualityAssuranceHeroImage}
                showClient={true}
            />
            <AnimationRevealPage>
                <MainFeature
                    primaryButtonText="Lets Talk"
                    primaryButtonUrl="https://wa.me/919971200884/?text=Hi, We would like to create an app"
                    heading="Ensuring Excellence: EiSolutions' Commitment to Quality Assurance."
                    description="<p>At EiSolutions, we are committed to upholding the highest standards of quality in everything we do. Our quality assurance team employs rigorous testing methodologies and stringent quality control measures to ensure that every project meets our clients' expectations.<br><br>
                    From initial concept to final delivery, we place a strong emphasis on precision and attention to detail. Through comprehensive testing protocols and continuous feedback loops, we identify and address any issues or discrepancies, ensuring that our solutions perform flawlessly across all platforms and devices.<br><br>
                    With EiSolutions, you can trust that your project is in good hands. Our unwavering commitment to quality assurance means that you can rely on us to deliver exceptional results every time. Let us help you achieve excellence in your digital endeavors.</p>"
                    subheading=""
                    textOnLeft={false}
                    imageSrc={qualityAssurance}
                    imageBorder={false}
                    imageDecoratorBlob={false}
                />
                <DevelopmentProcess />
                <PreFooter fromPage="Customised Apps" formheading="Building Apps with Brand Identity Amplifies User Engagement & Conversion" formsubheading="Book Our Free Discussion Session Today - " heightlightText="Significance of Brand Identity and User Engagement in Online Business." />
            </AnimationRevealPage>
            <Footer />
        </>
    )
}

export default QualityAssurance