import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as ArrowForward } from "images/arrowForward.svg";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { LinkButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import {ReactComponent as SvgDotPattern } from "images/dot-pattern.svg"

const Container = tw.div`relative xs:px-10 bg-baseGray`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:pt-12 items-center xs:py-10`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-5 lg:mr-16 md:order-last` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);


const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-left md:text-left`;
const Heading = tw.h4`text-black font-bold text-left xs:text-xl sm:text-2xl text-left md:text-left leading-tight`;
const Description = styled.div`
    ${tw`mt-4 text-left md:text-justify text-sm xs:text-base sm:text-lg font-medium leading-relaxed text-textBlack`}
    .textContainer p{
      ${tw`mt-5`}
    }
    .textContainer ul{
      list-style:disc;
      margin-left:19px;
      width:90%;

    }
    .textContainer li{
      padding-bottom:15px;
    }

`;


const LinkButton = styled(PrimaryButtonBase)(props => [
  tw`bg-brand-500 hover:bg-brand-700 hover:text-white mt-12 text-sm inline-block mx-auto md:mx-0 rounded-full text-white p-2 px-5`,
  props.buttonRounded && tw`rounded-full`
]);

const IconContainer = tw.i``;
const DescContainter = tw.div`xs:w-full md:w-10/12`;


export default ({
  subheading = "",
  heading = "",
  description = "",
  primaryButtonText = "",
  primaryButtonUrl = "",
  imageSrc = TeamIllustrationSrc,
  buttonRounded = false,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image css={imageCss} src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnRight={textOnLeft}>
          <TextContent>
            <DescContainter>
              {heading && <Heading>{heading}</Heading>}
              {subheading && <Subheading>{subheading}</Subheading>}
              {description && <Description> 
                <div className="textContainer" dangerouslySetInnerHTML={{ __html:description}}></div>
              </Description>}
              {primaryButtonText && 
                <LinkButton buttonRounded={buttonRounded} className="exploreBtn" as="a" href={primaryButtonUrl}>
                  {primaryButtonText} <IconContainer><ArrowForward className="btnArrow" /></IconContainer>
                </LinkButton>
              }
            </DescContainter>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
