import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";

import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import WhyChooseUs from "components/features/ThreeColWithSideImage.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0 xs:px-5 xs:text-center lg:text-left lg:px-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);
const GrayBg=  tw.div`bg-baseGray`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left xs:text-center  lg:text-center `; 
const ColumnHeading = tw.h2`text-gray-800`;
const Subheading = tw(SubheadingBase)`text-gray-700 text-base text-center font-normal px-8 sm:px-0`;

export default ({
  textOnLeft = true,
  fromPage = "Contact Us",
  wappAllow = true,
  formActionUrl = "",
  formheading = "Moving Your Brand & Content Online Ampliflies Your Business & Growth ", 
  formsubheading = "Book Our Free Discussion Session Today -",
  heightlightText = "How to Launch and Grow Digital Content Business",
}) => { 
  const msgColor ={color:"#333"};
  return (
    <AnimationRevealPage>
      <Header />
      
          <ContactUsForm fromPage={fromPage} wapp={wappAllow} formActionUrl = {formActionUrl}/>
      <GrayBg>
        <WhyChooseUs 
          subheading={<Subheading>We deliver more than just a business solution. We express our long-term interest in your brand and believe in a handshake that lasts a lifetime.</Subheading>}

          heading={
            <>
              Why Choose Us?
            </>
          }
        />
      </GrayBg>
      
      <Footer />
    </AnimationRevealPage>
  );
};
