import React from "react";

import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as QuoteImage } from "images/aurum_Icons/quotes.svg";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingmd } from "components/misc/Layouts.js";
import { LinkButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as ArrowForward } from "images/arrowForward.svg";

const Row = tw.div `xs:px-6 xs:py-6 py-20 px-20 sm:py-10 sm:px-10 md:px-24`;
const Header = styled.div`
    ${tw`flex flex-col xs:flex-row justify-between items-center`}    
`;
const Image = tw.img`mx-auto w-1/3`;
const TestimonialText =tw.div`text-center text-base pt-10`;

const Separator = styled.span`
  ${tw`bg-gray-500 rounded`}
  height: 4px;
  width: 70px;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
`;
const CustomerInfoAndControlsContainer = tw.div`mt-10 flex justify-center items-center flex-col sm:flex-row mx-auto`;
const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-16 h-16 sm:w-20 sm:h-20`;
const CustomerTextInfo = tw.div`text-center md:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-bold text-xl`;
const CustomerTitle = tw.p`font-medium text-secondary-100`;

const Footer = tw.section`bg-baseGray py-2 text-center px-2`;
const LinkButton = styled(PrimaryButtonBase)(props => [
  tw`text-sm inline-block mx-auto md:mx-0 justify-center`,
  props.buttonRounded && tw`rounded-full`
]);
const IconContainer = tw.i``;
const TopHeader = tw.div`text-center my-8 font-bold text-3xl`;

export default ({
  imageSrc = '',
  testimonialText="",
  profileImageSrc="",
  customerName="",
  customerTitle="",
  buttonUrl="",
  variant=""
}) => {

  return (
    <Container tw="sm:py-20">
      {variant == "clientText" && <TopHeader>Words from Our Happy Clients</TopHeader>}
      <ContentWithPaddingmd className="shadow-box">
        <Row>
          {imageSrc !== '' && 
            <Header>
              <QuoteImage className="quotesImg" />
              <Image src={imageSrc} />
              <QuoteImage className="quotesImg reverse-symbol" />
            </Header>
          }
          {testimonialText && <TestimonialText>{testimonialText}</TestimonialText>}
          <Separator />  
          <CustomerInfoAndControlsContainer>
            <CustomerInfo>
              <CustomerProfilePicture src={profileImageSrc} alt={customerName} />
              <CustomerTextInfo>
                <CustomerName>{customerName}</CustomerName>
                <CustomerTitle>{customerTitle}</CustomerTitle>
              </CustomerTextInfo>
            </CustomerInfo>
          </CustomerInfoAndControlsContainer>
        </Row>
        {buttonUrl !== '' && 
          <Footer>
            <LinkButton className="exploreBtn" as="a" href={buttonUrl}>
              Read full case study <IconContainer><ArrowForward className="btnArrow" /></IconContainer>
            </LinkButton>
          </Footer>
        }
      </ContentWithPaddingmd>
    </Container>
  );
};
