import React from 'react'
import { Helmet } from 'react-helmet';
import tw from "twin.macro";
import Hero from "components/hero/AurumHeroContent.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import DevelopmentProcess from "components/development-process/";
import PreFooter from "components/footers/PreFooterBrand.js";
import ui_ux_hero_image from 'images/ui-ux-hero-image.jpg'
import ui_ux from 'images/ui-ux.jpg'
import Footer from "components/footers/SimpleFiveColumn.js";

export default () => {
    const Subheading = tw.p`tracking-widest text-textBlack text-center`;
    const HighlightedText = tw.span`text-primary-500`;
    const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
    const TITLE = 'UI&UX';
    const tagNameD = `Elevate your digital presence with expert UI & UX design services. Our team crafts intuitive, interfaces that enhance user experience with WIX development, Shopify store development.`;

    return (
        <>
            <Helmet
                title={TITLE}
                meta={[
                    {
                        name: 'description',
                        content: { tagNameD },
                    },
                    {
                        property: `og:title`,
                        content: { TITLE },
                    },
                    {
                        property: `og:description`,
                        content: { tagNameD },
                    },
                    {
                        name: 'keywords',
                        content: 'Wireframing, Shopify store development, WIX development, Usability Testing, User Persona, User Journey/User Flow, Information Architecture',
                    }]}
            />
            <Hero roundedHeaderButton={true}
                mainTitle="UI&UX"
                subTitle={tagNameD}
                buttonText="Lets Go"
                buttonUrl="https://wat.me/919971200884/?text=Hi, We would like develop our App"
                imageSrc={ui_ux_hero_image}
                showClient={true}
            />
            <AnimationRevealPage>
                <MainFeature
                    primaryButtonText="Lets Talk"
                    primaryButtonUrl="https://wa.me/919971200884/?text=Hi, We would like to create an app"
                    heading="Crafting Seamless Digital Experiences with EI Solutions."
                    description="<h3 style='font-weight:bold'>User Interface (UI) Design</h3><p>At EI Solutions, we create visually appealing and intuitive interfaces, focusing on user-friendly navigation and aesthetic layouts.</p><p></p><h3 style='font-weight:bold'>User Experience (UX) Design</h3><p>Our UX design at EI Solutions prioritizes usability and accessibility, ensuring seamless and enjoyable user interactions.</p><p></p><h3 style='font-weight:bold'>Responsive and Accessible Design</h3><p>EI Solutions designs responsive and accessible interfaces across all devices, ensuring a consistent and inclusive user experience.</p>"
                    subheading=""
                    textOnLeft={false}
                    imageSrc={ui_ux}
                    imageBorder={false}
                    imageDecoratorBlob={false}
                />
                <DevelopmentProcess />
                <PreFooter fromPage="Customised Apps" formheading="Building Apps with Brand Identity Amplifies User Engagement & Conversion" formsubheading="Book Our Free Discussion Session Today - " heightlightText="Significance of Brand Identity and User Engagement in Online Business." />
            </AnimationRevealPage>
            <Footer />
        </>
    )
}