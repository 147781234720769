import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainFeature2 from "components/features/TwoColWithMethodology.js";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
import Stats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import Hero from "components/hero/AurumHeroContent.js";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import DesignIllustration from "images/aurum_apps_home_2.gif";
import PreFooter from "components/footers/PreFooterBrand.js";
import Design from "images/about_us_side_banner.png";


export default () => {
  const Subheading = tw.span`uppercase tracking-wider text-sm`;
  const HighlightedText = tw.span`text-primary-500`;
  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} 
        mainTitle="We Empower Businesses to Expand Technical Expertise" 
        subTitle="In today's dynamic business landscape, online presence is pivotal for growth. Recognizing the challenges businesses encounter in navigating the vast realm of technology, we understand the impracticality for every business owner to master every facet. At EiSolutions, we firmly believe in providing unwavering support to businesses, fostering an environment that empowers them to thrive with unwavering strength and vitality."
        imageSrc={Design}
        showClient= {false}
      />
      <MainFeature2
        heading={
          <>
           Make Your Business More Competitive And Enduring
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        description={`<h3 style="font-weight:bold">Our Mission</h3><p>Our Mission is seeing businesses growing with our skills & efforts, Which makes us more happy is that a business which was associated with us and shows a growth that makes us more motivated to do so.</p><p></p><h3 style="font-weight:bold">Our Vision</h3><p>Our Vision is to create a name in the Industry which associated with creating an environment for Businesses in which they feel Confident, Independent in this growing techy world. Most of the businesses who comes to us feels helpless & our Vision to make them feel Confident.</p>`}
      />
      <Stats />
      <PreFooter />
      {/*<TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      />*/}
      <Footer />
    </AnimationRevealPage>
  );
};
