import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Modal from "react-modal";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-210312445-1');
ReactGA.pageview(window.location.pathname + window.location.search);

Modal.setAppElement("#root");

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
