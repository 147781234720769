import React from 'react'
import { Helmet } from 'react-helmet';
import tw from "twin.macro";
import Hero from "components/hero/AurumHeroContent.js";
import supportAndMaintenanceHeroImg from "images/SupportAndMaintenance-new.svg";
import Footer from "components/footers/SimpleFiveColumn.js";
import DevelopmentProcess from "components/development-process/";
import PreFooter from "components/footers/PreFooterBrand.js";
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import MainFeature from "components/features/TwoColWithButton.js";
import styled from "styled-components";
import whyEisolutions from 'images/why-eisolutions-new.svg'
import amcImg from 'images/AMC.png'
import amcPlans from 'images/AMC-plans.png'
import IT_Issues from 'images/IT-issues.png'

// .container {
//   ${tw`text-center p-4`}
// }

const Container = tw.div`relative md:px-10 px-5 bg-baseGray my-3`;
const AMCSupportSection = styled.div`
  ${tw`px-0 w-full mx-auto bg-baseGray text-center flex justify-between md:flex-row flex-col flex-wrap md:py-20 py-4`}

  .textContainer {
    ${tw`rounded-md mx-auto bg-white shadow-lg md:w-[32%] w-full md:mt-10 mt-24 p-3`}
  }
  .text{
    ${tw`mt-20`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-lg text-black leading-tight`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-base leading-loose`}
  }
  .description p{
    ${tw`mt-5`}
  }
  .imageContainer {
    ${tw`flex justify-center relative w-full`}
  }
  .imageContainer > img {
    ${tw`min-w-[120px] max-w-[120px] w-[120px] h-[120px] shadow-lg absolute top-0 -mt-16  bg-baseGray`}
  }
`;

export default () => {
  const Subheading = tw.p`tracking-widest text-textBlack text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
  const TITLE = 'Support And Maintenance';
  const tagNameD = `Ensure your business runs smoothly with our comprehensive IT support services. We provide IT manpower supply, Hardware maintenance, Govt Tenders to keep your systems up & running.`;

  const cardContents = [
    {
      id: 1,
      heading: "Reliable Annual Maintenance Contract",
      image: amcImg,
      para: "We offer comprehensive IT Hardware support for Servers (including OS), Man Power Supply, Storage Devices, Switches, Website AMC & Networking Devices. At EiSolutions, we specialize in ensuring maximum uptime with quick issue resolution and preventive maintenance, adhering to strict SLAs tailored to your business needs, all while being cost-effective."
    },
    {
      id: 2,
      heading: "Our Commitment to Minimizing IT Issues",
      image: amcPlans,
      para: "EiSolutions is dedicated to simplifying IT management and reducing related challenges. As a leading IT service provider in Delhi-NCR, we offer solutions for businesses, government agencies, educational institutions, and healthcare facilities. Our services include support for business laptops, desktops, servers, storage, and networking devices, ensuring smooth and efficient operations."
    },
    {
      id: 3,
      heading: "Comprehensive and Non-Comprehensive AMC Plans",
      image: IT_Issues,
      para: "Our comprehensive and non-comprehensive AMC plans provide the perfect solution for your organization’s needs. Choose EiSolutions for dependable IT support and maintenance, ensuring seamless operations and minimizing downtime with our tailored services. We offer reliable support for your IT infrastructure, guaranteeing smooth business continuity."
    },
  ]

  return (

    <>
      <Helmet
        title={TITLE}
        meta={[
          {
            name: 'description',
            content: { tagNameD },
          },
          {
            property: `og:title`,
            content: { TITLE },
          },
          {
            property: `og:description`,
            content: { tagNameD },
          },
          {
            name: 'keywords',
            content: "IT Support Services, System maintenance, Technical support, Preventive maintenance, Govt Tenders, IT manpower supply, Software updates"
          }
        ]}
      />
      <Hero roundedHeaderButton={true}
        mainTitle="Support And Maintenance"
        subTitle={tagNameD}
        buttonText="Lets Go"
        buttonUrl="https://wa.me/919971200884/?text=Hi, We would like develop our App"
        imageSrc={supportAndMaintenanceHeroImg}
        showClient={true}
      />

      <AnimationRevealPage>
        <MainFeature
          primaryButtonText="Lets Talk"
          primaryButtonUrl="https://wa.me/919971200884/?text=Hi, We would like to create an app"
          heading="Why Choose EiSolutions"
          description="
            <p>We are dedicated to delivering complete IT solutions to a wide range of sectors, including the Government Sector, Central Government Ministries, State Government Departments, Corporate Sector, and other Government Departments and Public Sector Undertakings (PSUs). Our expertise extends to providing Computer AMC Services throughout Delhi & NCR.</p>
            <p></p>
          "
          subheading=""
          textOnLeft={false}
          imageSrc={whyEisolutions}
          imageBorder={false}
          imageDecoratorBlob={false}
        />

        <Container>
          <AMCSupportSection>
            {/* create cards horizontally */}
            {cardContents.map(cardContent => (
              <div className="textContainer" key={cardContent.id}>
                <div className='imageContainer'>
                  <img src={cardContent.image} alt={cardContent.heading} />
                </div>
                <div className="text">
                  <h3 className="title">{cardContent.heading}</h3>
                  <div className="description" dangerouslySetInnerHTML={{ __html: cardContent.para }}></div>
                </div>
              </div>
            ))}
          </AMCSupportSection>
        </Container>
        <PreFooter fromPage="Customised Apps" formheading="Building Apps with Brand Identity Amplifies User Engagement & Conversion" formsubheading="Book Our Free Discussion Session Today - " heightlightText="Significance of Brand Identity and User Engagement in Online Business." />
      </AnimationRevealPage>
      <Footer />
    </>
  )
}