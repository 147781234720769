import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainBanner from "components/hero/AurumHeroContent.js";
import Stats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImage.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import { Helmet } from 'react-helmet';

const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-brand-500`;
const HighlightedText = tw.span`text-brand-500 `;

const BoldTag = styled.span`
   ${tw`text-textBlack font-bold`}
`;
export default ({
 title = "Simplified On-Boarding",
 highlightedtext = "Get Started.",
 heading = "Easy to",
 CheckIcon = Checkicon,
}) => { 
  return (
    <>
    <Helmet
      
      title='Secured Video Streaming'
      
      meta={[
        {
          name: 'description',
          content: 'Video Streaming APIs for Web and Apps. Live and Video on Demand Streaming',
        },
        {
          property: `og:title`,
          content: 'Video Streaming APIs for Web and Apps. Live and Video on Demand Streaming',
        },
        {
          property: `og:description`,
          content: 'Video Streaming APIs for Web and Apps. Live and Video on Demand Streaming',
        }]}
    />
    <AnimationRevealPage>
      <MainBanner roundedHeaderButton={true} 
        mainTitle="Secured Video Streaming" 
        subTitle={
          <>
          Add Live Video or Video on Demand to any Product and Platform. Agile & Secure Video Hosting & Delivery APIs. Build to Deliver Content at Scale on Web & Apps <br /><br />APIs for Node, Android, Angular, PHP and Most Common Platforms.
          </>
        }
        buttonText="Let's Talk"
        buttonUrl="https://wa.me/918826622806/?text=Hi, I would like to GROW my Business with Aurum Content Delivery Platform."
        imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/code_typing.gif"
        showClient= {false}
        />
      <Stats/>
      <OurExpertise
       description = {
        <>
          <ul>
          <li>Deliver <BoldTag>Live and Video on Demand</BoldTag> on Mobile / Desktop Apps &amp; Browsers.</li><li>Most <BoldTag>Competitive Pricing</BoldTag> on Video Hosting and Streaming.</li><li>All Videos Are Streamed in HLS format with <BoldTag>Global Encryption Standards</BoldTag>.</li></ul> 
        </>
      }
        subheading = {
          <>
          The Core Idea
          </>
        }
        heading = {
          <>
            APIs for Business Focussed On <span tw="text-brand-500">Brand &amp; Content Security</span>
          </>
        }
        features = {"API"}
        imageSrc = "https://ik.imagekit.io/zjste1wkulv/tr:q-90/aurum_for_business.gif"
      />
      <ContactDetails heading="First All in One Content Delivery Platform" subheading="" description="Everything You Need to Deliver & Monetise Content "/>
      <FeatureWithSteps
        subheading={<SubheadingSteps>Simplified Integration</SubheadingSteps>}
        heading={
          <>
            {heading} <HighlightedText>{highlightedtext}</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/easy_to_launch.gif"
        steps={
            [
              { 
                mainIcon:"",
                heading: "Unify Content",
                description: "Manage & Structure Content from a Single Library - Live and Video on Demand" 
              },

              {
                mainIcon:"",
                heading:"Integrate",
                description: "Integrate APIs and Aurum Player in Web & Apps."
              },

              {  
                mainIcon:"",
                heading: "Analytics & DRM",
                description: "Encryption and Detailed Analytics of Videos."
              }
            ]
          }
      />
      
      <TrackRecord 
      textOnLeft={true}
      heading={
        <>
          Why <HighlightedText>Aurum</HighlightedText> is the Best Choice
        </>
      } description = 
        {
          <>
            Its the New Front Door for the Brands to Deliver Content Across Globe on Almost All Devices. Aurum makes it easy for the Content Owners and Developers to Build Faster and Manage Delivery at Scale.<p>&nbsp;</p><p>It is the easiest and fastest way to Streamline, Modernise and Monetise Content. <BoldTag>Let’s build better digital experiences together.</BoldTag></p>
          </>

        } imageSrc = "https://ik.imagekit.io/zjste1wkulv/tr:q-50/why_aurum_api.png"
      subheading = "The Modern Content Delivery & Monetisation Platform"
      />
      <Testimonials description = {
        <>
          We work with you from start to finish. We view ourselves as an extension of your team, and as your partner, we pride ourselves in being fully engaged with your company to help you find opportunities for growth. <BoldTag>The Reason why Content Owners Love Aurum</BoldTag> 
        </>
      } imageSrc = "https://ik.imagekit.io/zjste1wkulv/tr:q-90/testimonials2.jpg"
        />
      <Faqs
      faqs={[
            {
              question: "Who should use Aurum APIs ?",
              answer:"<p>Aurum APIs are Best Suited for the Content Owners where Branding and Content Security is of Prime Concern.</p><br /><p>Aurum APIs and Platform enabled VOD and Live with full encryption and easy File Management.</p><br /><p>If You are Looking to Stream Videos for Free, then Probably you should opt for Youtube.</p>"
            },
            {
              question: "What Kind of Video Formats are Supported and Used ?",
              answer:
                "<p>Video in Mp4 and HLS Formats can be hosted direclty. Videos in Other Formats need to be converted in these formats using Free Tools Available.</p><br /><p>Aurum Streams all Videos with Enterprise Grade DRM and in Appls HLS Format Only.</p>"
            },
            {
              question: "How does the Pricing Works ?",
              answer:
                "Aurum Pricing is always Pay as You Go, with all pricing based on GB of data stored and GB of data streamed. 5 Hrs of very good quality video usually forms 1 GB of Video data. Our pricing is based on usage and volume, and hence the best pricing is offered once we have a complete idea about your use case and volume.<br /><br />Contact our sales team for the best quote today."
            },
            {
              question: "Can we Offer Ads in Recorded Videos ?",
              answer:
                "Aurum platforms and player enables Overlay Banner Ads and Video Ads. If you are an Influencer, and seek to monetise, get in touch with us today to explore and execute the best business model for you."
            }
          ]}
      textOnLeft={true}
      />
      <PreFooter fromPage = "Content Delivery Platform" formheading = "Aurum APIs Enables Rapid Business Growth with Simplified Video Hosting, Security & Delivery." formsubheading = "Book Our Free Discussion Session Today - " heightlightText="The Benefits of Using a Aurum APIs over Other Available Services."/>
      <Footer />
    </AnimationRevealPage>
  </>
  );
  
};
