import React, {useEffect} from "react";

import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import Hero from "components/hero/ItServiceBanner.js";
//import Features from "components/features/ThreeColWithTopImage.js";
import Features from "components/features/ThreeColItService.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithMethodology.js";
import WhyChooseAurum from "components/features/TwoColWithThreeCardsLayoutItService.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
// import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import OnlineShopImage from "images/aurum_Icons/online-shop.svg";
import ElearningImage from "images/aurum_Icons/elearning.svg";
import GovtTenderImage from "images/aurum_Icons/govt-tender.svg";
import EmailMarketingImage from "images/aurum_Icons/email-marketing.svg";
import RealEstateImage from "images/aurum_Icons/realestate.svg";
import OnDemandSolutionImage from "images/aurum_Icons/solution.svg";
import YoutubeImage from "images/aurum_Icons/yt.svg";
import APIsImage from "images/aurum_Icons/api.svg";
import DynamicContentImage from "images/aurum_Icons/dynamic.svg";
import VideoContentImage from "images/aurum_Icons/aurum-video-library.svg";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import DevicesImage from "images/aurum_Icons/aurum-devicesImage.svg";
import HowWeWorkImage from "images/aurum_Icons/how-we-work_new_demo.gif";
import caseStudyLogoImage from "images/tsilogo.png";
import caseStudyProfileImage from "images/tsi_deepak_tek.png"
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import DigitalPartner from "images/aurum_Icons/Dpartner7.svg";
import { components } from "ComponentRenderer.js";

// Live Chat plugin Tawkto
import tawkTo from "tawkto-react";

// seo plugin Helmet
import { Helmet } from 'react-helmet';


export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  }) => {
  const Subheading = tw.p`tracking-widest text-textBlack text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
  const WhyChooseWrap = tw.div`overflow-hidden`;
  

  const tawkToPropertyId = '616306cf157d100a41abb1ea';

  const tawkToKey = '1fhld5795';
  
  useEffect(() => {
      tawkTo(tawkToPropertyId, tawkToKey)
  }, [])
  return (
    <>
      <Helmet
      
      title='Aurum Labs - Content Delivery and Monetisation'
      
      meta={[
        {
          name: 'description',
          content: 'One Platform for Monetisation, Video Streaming, Content Delivery through Apps & APIs.',
        },
        {
          property: `og:title`,
          content: 'Aurum Labs - Content Delivery and Monetisation',
        },
        {
          property: `og:description`,
          content: 'One Platform for Monetisation, Video Streaming, Content Delivery through Apps & APIs.',
        }]}
    />
      <Hero 
        roundedHeaderButton={true} 
        mainTitle="Your Partner From Ideation To Implementation" 
        subTitle="Connect with us to create your intuitive web and mobile applications."
        buttonText="Lets Talk"
        imageSrc={DigitalPartner}
        showClient= {true}
      />
      <AnimationRevealPage>
        <Features
          subheading={<Subheading>Content Platform that makes Planning, Delivery and Engagement Easier than Ever</Subheading>}

          heading={
            <>
              One Platform, Endless Potential 
            </>
          }
        />
        <VerticalSeprator className="v-seperator" />
        <WhyChooseWrap>
            <WhyChooseAurum 
              heading="Industries We Serve"
              subheading="Here, We make almost every genre of applications. You name it and we build it."
              cards={[
                {
                  imageSrc: OnlineShopImage,
                  // subText: "Turn Your Broadcast as an Event with Data Capture, Entry Ticket and Engaging Features.",
                  title: "Retail,E-commerce",
                  
                },
                {
                  imageSrc: ElearningImage,
                  // subText: "Stream ZOOM Conferences to a Large Audience with Chat Interaction. Auto Saved in Archives.",
                  title: "Education & E-learning",
                  
                },
                {
                  imageSrc: GovtTenderImage,
                  // subText: "Live Streaming, Content Libraries and All Sorts of Features to make it a complete experience",
                  title: "Government Sectors",
                  
                },
                {
                  imageSrc: EmailMarketingImage,
                  // subText: "Publish PDFs, HTMLs and Monetise. Design dynamic revenue models like ebook renting.",
                  title: "Email Marketing",
                  
                },
                {
                  imageSrc: RealEstateImage,
                  // subText: "Archive your Online & Offline Events, Organise and Share to Capture Data, Monetisation and Branding.",
                  title: "Real Estate",
                  
                },
                {
                  imageSrc: OnDemandSolutionImage,
                  // subText: "Webinars are one sided, Aurum ups the engagement and interactivity.",
                  title: "On-Demand Solutions",
                  
                },
                
              ]}
              
            />
        </WhyChooseWrap>
        <MainFeature
          primaryButtonText=""
          primaryButtonUrl = ""
          heading="Aurum Methodology"
          description="Based on this experience, Aurum Labs designed a custom development methodology based on Agile development processes for the project management and execution. We call it the D6 Development Methodology. We divide the complete project into multiple 2 to 4 week sprint cycle. The duration of the sprint cycle varies from project to project. And for each sprint we follow the D6 methodology.</p>"
          subheading=""
          imageSrc={HowWeWorkImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <CaseStudy
          imageSrc={caseStudyLogoImage}
          testimonialText = '“We began looking for a new platform in April 2019 and were keen to get one in place and optimised ahead of the Summers peak period. Aurum Labs ensured that the implementation process was as smooth as possible, and the apps were up and running within 5 days.”'
          profileImageSrc={caseStudyProfileImage}
          customerName="Deepak Tekchandani"
          customerTitle="Founder, CEO"
          buttonUrl= ""
          variant="clientText"
        />
        {/*<MainFeature
          primaryButtonText="Explore More"
          primaryButtonUrl = {innerPages.customisedapps.url}
          heading="Develop Strong Brand Image & Deep Engagement with UX / CX"
          description="Provide the best, Most intuitive apps across all devices.<br/><br /> Achieve 20x user engagement by customising the app UX and CX. Build and Deliver Content Experiences that establishes Your Brand Identity in the market. <p>Aurum understands and knows that every industry requires Unique User Flows and Content Delivery Framework.</p>"
          subheading=""
          textOnLeft={false}
          imageSrc={StrongBrandImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText="Explore More"
          primaryButtonUrl = {innerPages.videostreaming.url}
          heading="Deliver Compelling Video Content Anywhere"
          description="Our streaming services process and deliver high-quality, consistent playback for Live and Recorded Streams.<p>Now reach audiences on every device, across platforms with Videos - <b>LIVE and RECORDED</b>, online and offline.</p><p>Aurum Delivers a beautiful experience and increases your user base and revenues.</p>"
          subheading=""
          imageSrc={VideoContentImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText="Learn More"
          primaryButtonUrl = {innerPages.contentdeliveryplatform.url}
          heading="Deliver Content on Apps & Devices that Users Love"
          description="Impress Users with availability of the Content in their choice of devices on <b>Aurum Apps</b> or integrate the content from Aurum Content Library to your existing Web & Apps easily with APIs.<p>Deliver Content Online or Offline (Pen Drive & Google Drive) and enable seamless transitions at any point in their journey. Achieve all this with Ease and Enterprise Grade Security for Videos and PDFs.</p> <p>Deliver Live Sessions on Laptops with Complete Content Security from Downloads and Screen Capture, enabling much more user engagement and satisfaction in long duration sessions.</p>"
          subheading=""
          textOnLeft={false}
          imageSrc={DevicesImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        */}
        <PreFooter fromPage="Home Page"/>
      </AnimationRevealPage>
      <Footer />
   </>
  );
}
