import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";
import AnimatedText from "./AnimatedText.js";
import OurClients from "../clients/Clients.js";


const Container = tw.div`relative lg:px-0 xs:px-2`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto md:pt-20 lg:pt-0 border-b-1`;
const LeftColumn = tw.div`relative lg:w-6/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-black leading-tight mt-24 md:mt-0`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md mx-auto lg:mx-0`}
  button {
    ${tw`w-8/12 bg-brand-100 text-gray-100 xs:m-auto lg:ml-0 lg:mr-2 my-4 sm:my-2 py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-brand-200 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

const ClientSection = tw.section`text-center bg-indigo-800 max-w-screen-xl mx-auto rounded-lg`;

export default ({ 
  roundedHeaderButton,
  mainTitle='',
  subTitle='',
  imageSrc='',
  buttonText='',
  buttonUrl='https://wa.me/919971200884/?text=Hi, I am interested to take eisolutions services.', 
  showClient= true
}) => {
  // <AnimatedText />
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            {mainTitle && (
              <Heading>
              {mainTitle}
              </Heading>
            )}
            {subTitle && (
              <Paragraph>
                {subTitle}
              </Paragraph>
            )}
            {buttonText && (<Actions>
              <a  href={buttonUrl} target="_blank"><button className="bt-round">{buttonText}</button></a>
            </Actions>)
            }
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={imageSrc} alt="Design Illustration" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        {showClient == true && (
          <>
            <ClientSection>
              <OurClients />
            </ClientSection>
          </>
        )}
      </Container>
    </>
  );
};
