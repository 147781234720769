import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo-light.svg";
import StartupLogo from "../../images/fixed-startup-india-logo.png";
import { components } from "ComponentRenderer.js";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import UKASL from "../../images/ukasl.png";
import ISO from "../../images/iso.png";
import EGAC from "../../images/egac.png";
import IAF from "../../images/iaf.png";
import RO from "../../images/ro.png";
import CertificationLogo from "../../images/certificationLogo.png";
import ISO9001 from "../../images/certificate/iso-9001.png";
import ISO20000 from "../../images/certificate/iso-20000.png";
import ISO27001 from "../../images/certificate/iso-27001.png";
import OSC from "../../images/certificate/osc.png";

const Container = tw.div`relative bg-indigo-800 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-10 lg:py-10 lg:flex lg:flex-row justify-between xs:flex xs:flex-col`;

const Column = tw.div`mt-5 md:w-1/6`;
const WideColumn = tw(Column)`md:text-left lg:w-1/4 md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold text-white`;

const LinkList = tw.ul`mt-4 text-sm font-medium `;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent text-white opacity-75 focus:opacity-100 hover:border-brand-500 pb-1 transition duration-300`;
const LinkB = tw.a`ml-5 border-b-2 border-transparent text-white opacity-75 focus:opacity-100 hover:border-brand-500 pb-1 transition duration-300 first:ml-0 `;
const LinkC = tw.a`border-b-2 border-transparent text-black focus:text-blackStep-100 focus:border-blackStep-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`md:justify-start`;

const AlIngenious = tw.h1`mt-2 text-lg font-bold text-black`;
const LogoImg = tw.img`w-1/2`;
/*const CertifiedContainer = tw.div``;*/
const CertifiedContainer = styled.ul`
  ${tw`justify-start`}
  li {
    ${tw`w-full mt-4`}
  }
`;
const StartupImg = tw.img`w-1/2 py-4`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 font-medium text-sm md:mx-0 md:mr-4 text-white opacity-75`;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const Divider = tw.div`h-px bg-gray-100  opacity-50 mt-4 w-[25%] md:w-[60%]`;

export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  certifiedLogo = [
    {
      logoSrc: ISO9001,
      certifiedName: "iso9001",
    },
    {
      logoSrc: ISO20000,
      certifiedName: "iso20000",
    },
    {
      logoSrc: ISO27001,
      certifiedName: "iso27001",
    },
    {
      logoSrc: OSC,
      certifiedName: "osc",
    },

    // {
    //     logoSrc: UKASL,
    //     certifiedName: "ukasl",
    //   },
    //   {
    //     logoSrc: ISO,
    //     certifiedName: "iso",
    //   },
    //   {
    //     logoSrc: EGAC,
    //     certifiedName: "egac",
    //   },
    //   {
    //     logoSrc: IAF,
    //     certifiedName: "iaf",
    //   },
    //   {
    //     logoSrc: RO,
    //     certifiedName: "ro",
    //   },
    // {
    //   logoSrc: CertificationLogo,
    //   certifiedName: "certificationlogos",
    // },
  ],
}) => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} className="aurum-logo" />
          </LogoContainer>

          {/*<SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>*/}

          <CompanyDescription>
            <ColumnHeading>Contact Details</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <LinkB href={"tel:+919990576161"}>
                  <b>Phone:</b> +91-9990-57-6161
                </LinkB>
              </LinkListItem>
              <LinkListItem>
                <LinkB href={"mailto:info@eisolutions.in"}>
                  <b>Email:</b> info@eisolutions.in
                </LinkB>
              </LinkListItem>
              {/* <LinkListItem>
                <LinkB href={"#"}>
                  <b>Address:</b> G-394A, West Karawal Nagar, Delhi - 110094
                </LinkB>
              </LinkListItem> */}
            </LinkList>
          </CompanyDescription>
        </WideColumn>
        <Column>
          <ColumnHeading>Services</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href={innerPages.mobileapps.url}>
                Mobile App Development
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.websitedevelopement.url}>
                Website Development
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.graphicdesign.url}>
                Design (UI/UX/Graphic)
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.enterpriseappdevelopment.url}>
                Enterprise App Development
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.digitalmarketing.url}>
                Digital Marketing
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.supportandmaintenance.url}>
                Suppport & Maintenance
              </Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Company</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href={innerPages.aboutus.url}>About Us</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.contactus.url}>Contact Us</Link>
            </LinkListItem>
            <Divider />
            <LinkListItem>
              <LinkB href={innerPages.policycenter.url}>
                Legal Policy Center
              </LinkB>
            </LinkListItem>
            <LinkListItem>
              <LinkB href={innerPages.sitemap.url}>Sitemap</LinkB>
            </LinkListItem>
          </LinkList>
        </Column>

        <CompanyDescription>
          <ColumnHeading>Evansh Ingenious Solutions Pvt. Ltd.</ColumnHeading>
          <StartupImg src={StartupLogo} className="" />
          <CompanyDescription>Registration No. DIPP159003</CompanyDescription>

          <ColumnHeading>ISO 9001, ISO 20000 and ISO 27001 Certified</ColumnHeading>
          <CertifiedContainer>
            <ul style={{ display: "flex", flexWrap: "wrap"}}>
              {certifiedLogo.map((item, index) => (
                <li key={index} style={{ flex: "0 0 25%" }}>
                  <img
                    style={{ width: "78px", height: "46px"}}
                    src={item.logoSrc}
                    alt={item.certifiedName}
                  />
                </li>
              ))}
            </ul>
          </CertifiedContainer>
        </CompanyDescription>
      </FiveColumns>
    </Container>
  );
};
