import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import DevelopmentProcess from "components/development-process/";
import Hero from "components/hero/AurumHeroContent.js";
import Features from "components/features/ThreeColWithTopImage.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import VideoContentImage from "images/aurum_Icons/aurum-video-library.svg";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import DevicesImage from "images/aurum_Icons/aurum-devicesImage.svg";
import caseStudyLogoImage from "images/tsilogo.png";
import caseStudyProfileImage from "images/tsi_deepak_tek.png"
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import customappuiux from "images/custom_apps_main.png";
import mobileappdevHeroImg from "images/web-app-hero.png";
import { Helmet } from 'react-helmet';
export default () => {
  const Subheading = tw.p`tracking-widest text-textBlack text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
  const TITLE = 'Website Development';
  const tagNameD = `We are experts in web design and develop using latest technologies & tools to. Enhance your business with using latest technology like, node JS, Postgres, SQL, mongo dB etc.`;

  return (
    <>
      <Helmet

        title={TITLE}

        meta={[
          {
            name: 'description',
            content: { tagNameD },
          },
          {
            property: `og:title`,
            content: { TITLE },
          },
          {
            property: `og:description`,
            content: { tagNameD },
          },
          {
            name: 'keywords',
            content: "Postgres, mongo dB, Java script, CSS, WordPress, API integration"            
          }
        ]}
      />
      <Hero roundedHeaderButton={true}
        mainTitle="Website Development"
        subTitle={tagNameD}
        buttonText="Lets Go"
        buttonUrl="https://wa.me/919971200884/?text=Hi, We would like develop our App"
        imageSrc={mobileappdevHeroImg}
        showClient={true}
      />
      <AnimationRevealPage>
        <MainFeature
          primaryButtonText="Lets Talk"
          primaryButtonUrl="https://wa.me/919971200884/?text=Hi, We would like to create an app"
          heading="We just don’t create Applications - we provide end-to-end services for your business."
          description="<h3 style='font-weight:bold'>Security</h3><p>Our Applications are highly secure and we use the latest encryption techniques to secure the flow of data.</p><p></p><h3 style='font-weight:bold'>Deployment</h3><p>Post development, we deploy the solution onto your hosting server and manage all dependencies to ensure smoothness</p><p></p><h3 style='font-weight:bold'>Maintenance and Support</h3><p>We offer ongoing maintenance and support to keep your Application up to date and performing optimally</p>"
          subheading=""
          textOnLeft={false}
          imageSrc={customappuiux}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <DevelopmentProcess />
        <PreFooter fromPage="Customised Apps" formheading="Building Apps with Brand Identity Amplifies User Engagement & Conversion" formsubheading="Book Our Free Discussion Session Today - " heightlightText="Significance of Brand Identity and User Engagement in Online Business." />
      </AnimationRevealPage>
      <Footer />
    </>
  );
}
