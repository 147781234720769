import React, {useEffect} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/AurumHeroContent.js";
import Features from "components/features/ThreeColWithTopImage.js";
import DevelopmentProcess from "components/development-process/";
import WhyChooseUs from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import WhyChoose from "components/features/TwoColWithThreeCardsLayout.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
// import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import BroadCastImage from "images/aurum_Icons/broadcast.svg";
import ConferenceImage from "images/aurum_Icons/conference.svg";
import OnlineImage from "images/aurum_Icons/online.svg";
import EbooksImage from "images/aurum_Icons/ebook.svg";
import EventsImage from "images/aurum_Icons/event.svg";
import WebinarsImage from "images/aurum_Icons/web.svg";
import YoutubeImage from "images/aurum_Icons/yt.svg";
import APIsImage from "images/aurum_Icons/api.svg";
import DynamicContentImage from "images/aurum_Icons/dynamic.svg";
import VideoContentImage from "images/aurum_Icons/aurum-video-library.svg";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import DevicesImage from "images/aurum_Icons/aurum-devicesImage.svg";
import caseStudyLogoImage from "images/tsilogo.png";
import caseStudyProfileImage from "images/tsi_deepak_tek.png"
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import DesignIllustration from "images/aurum_Icons/heroImg.svg";
import { components } from "ComponentRenderer.js";

// Live Chat plugin Tawkto
import tawkTo from "tawkto-react";

// seo plugin Helmet
import { Helmet } from 'react-helmet';


export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  }) => {
  const Subheading = tw.p`tracking-widest text-textBlack text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
  const WhyChooseWrap = tw.div`bg-baseGray overflow-hidden`;
  const ServicesSection = tw.div`bg-baseGray lg:-mt-12`;
  // const DevelopmentProcessSection = tw.div`bg-baseGray lg:-mt-12`;
  const tawkToPropertyId = '6565a48f1db16644c5556906';

  const tawkToKey = '1hgaglkrj';
  
  useEffect(() => {
      tawkTo(tawkToPropertyId, tawkToKey)
  }, [])
  return (
    <>
      <Helmet
      
      title='Ei Solutions - Web, App Development, UI/UX and Branding agency'
      
      meta={[
        {
          name: 'description',
          content: 'Optimize, Modernize, and Tailor Your Digital Landscape with Our Technology Services',
        },
        {
          property: `og:title`,
          content: 'Ei Solutions - Web, App Development, UI/UX and Branding agency',
        },
        {
          property: `og:description`,
          content: 'Optimize, Modernize, and Tailor Your Digital Landscape with Our Technology Services',
        }]}
    />
      <Hero roundedHeaderButton={true} 
        mainTitle="Refine, Revamp, Personalize - Elevate Your Digital Evolution." 
        subTitle="We are a renowned web design and digital marketing services in worldwide. We are delivering top website, digital marketing solutions to clients throughout worldwide."
        buttonText="Lets Talk"
        imageSrc={DesignIllustration}
        showClient= {true}
      />
      <ServicesSection>
        <Features
          subheading={<Subheading>We constantly pursue tech updates to make sure we are constantly ahead of the curve with all the services we offer.</Subheading>}

          heading={
            <>
              OUR SERVICES 
            </>
          }
        />
      </ServicesSection>

      {/*<ProjectAreaOne />*/}
      <AnimationRevealPage>
        <WhyChooseUs 
          subheading={<Subheading>We deliver more than just a business solution. We express our long-term interest in your brand and believe in a handshake that lasts a lifetime.</Subheading>}

          heading={
            <>
              Why Choose Us?
            </>
          }
        />
        <VerticalSeprator className="v-seperator" />

        <DevelopmentProcess />
        {/*<CaseStudy
                  imageSrc={caseStudyLogoImage}
                  testimonialText = '“We began looking for a new platform in April 2019 and were keen to get one in place and optimised ahead of the Summers peak period. Aurum Labs ensured that the implementation process was as smooth as possible, and the apps were up and running within 5 days.”'
                  profileImageSrc={caseStudyProfileImage}
                  customerName="Deepak Tekchandani"
                  customerTitle="Founder, CEO"
                  buttonUrl= ""
                />
                <MainFeature
                  primaryButtonText="Explore More"
                  primaryButtonUrl = {innerPages.customisedapps.url}
                  heading="Develop Strong Brand Image & Deep Engagement with UX / CX"
                  description="<p>Provide the best, Most intuitive apps across all devices.</p>
                  <p>Achieve 20x user engagement by customising the app UX and CX. Build and Deliver Content Experiences that establishes Your Brand Identity in the market.</p>
                  <p>Aurum understands and knows that every industry requires Unique User Flows and Content Delivery Framework.</p>"
                  subheading=""
                  textOnLeft={false}
                  imageSrc={StrongBrandImage}
                  imageBorder={false}
                  imageDecoratorBlob={false}
                />
                <MainFeature
                  primaryButtonText="Explore More"
                  primaryButtonUrl = {innerPages.videostreaming.url}
                  heading="Deliver Compelling Video Content Anywhere"
                  description="<p>Our streaming services process and deliver high-quality, consistent playback for Live and Recorded Streams.</p>
                  <p>Now reach audiences on every device, across platforms with Videos - <b>LIVE and RECORDED</b>, online and offline.</p>
                  <p>Aurum Delivers a beautiful experience and increases your user base and revenues.</p>"
                  subheading=""
                  imageSrc={VideoContentImage}
                  imageBorder={false}
                  imageDecoratorBlob={false}
                />
                <MainFeature
                  primaryButtonText="Learn More"
                  primaryButtonUrl = {innerPages.contentdeliveryplatform.url}
                  heading="Deliver Content on Apps & Devices that Users Love"
                  description="<p>Impress Users with availability of the Content in their choice of devices on <b>Aurum Apps</b> or integrate the content from Aurum Content Library to your existing Web & Apps easily with APIs.</p>
                  <p>Deliver Content Online or Offline (Pen Drive & Google Drive) and enable seamless transitions at any point in their journey. Achieve all this with Ease and Enterprise Grade Security for Videos and PDFs.</p> 
                  <p>Deliver Live Sessions on Laptops with Complete Content Security from Downloads and Screen Capture, enabling much more user engagement and satisfaction in long duration sessions.</p>"
                  subheading=""
                  textOnLeft={false}
                  imageSrc={DevicesImage}
                  imageBorder={false}
                  imageDecoratorBlob={false}
                />
                <MainFeature
                  primaryButtonText=""
                  primaryButtonUrl = ""
                  heading="Develop Secured & Dynamic Content Libraries"
                  description="<p>Our Secured Content Servers use Enterprise Grade AES Encryption for Videos and PDFs. Now build your secured content library, curate contents dynamically to monetise in innovative ways.</p>
                  <p>Supported Content Formats - Live Streaming, ZOOM Integrations, Recorded Videos in MP4 / HLS Format, PDFs, interactive eBooks, MCQ & FIB Assessments.</p>
                  <p>Dynamically organise the contents from libraries to offer bundled products or anytime modify the contents of the offerings.</p>"
                  subheading=""
                  imageSrc={ContentSecurityImage}
                  imageBorder={false}
                  imageDecoratorBlob={false}
                />
        
                <WhyChooseWrap>
                  <WhyChoose 
                    heading="Ways to use Aurum"
                    subheading="Unlimited possibilities and Uniquely great experience every time"
                    noCardSpace={true}
                    cards={[
                      {
                        imageSrc: BroadCastImage,
                        subText: "Turn Your Broadcast as an Event with Data Capture, Entry Ticket and Engaging Features.",
                        title: "Broadcast as Event",
                        
                      },
                      {
                        imageSrc: ConferenceImage,
                        subText: "Stream ZOOM Conferences to a Large Audience with Chat Interaction. Auto Saved in Archives.",
                        title: "Conferences",
                        
                      },
                      {
                        imageSrc: OnlineImage,
                        subText: "Live Streaming, Content Libraries and All Sorts of Features to make it a complete experience",
                        title: "Online Courses",
                        
                      },
                      {
                        imageSrc: EbooksImage,
                        subText: "Publish PDFs, HTMLs and Monetise. Design dynamic revenue models like ebook renting.",
                        title: "Interactive eBooks",
                        
                      },
                      {
                        imageSrc: EventsImage,
                        subText: "Archive your Online & Offline Events, Organise and Share to Capture Data, Monetisation and Branding.",
                        title: "Events Archiving for Organisations",
                        
                      },
                      {
                        imageSrc: WebinarsImage,
                        subText: "Webinars are one sided, Aurum ups the engagement and interactivity.",
                        title: "Webinars",
                        
                      },
                      {
                        imageSrc: YoutubeImage,
                        subText: "Monetise, Serve and Engage Subscribed Users Better. Increase engagement and Brand Awareness.",
                        title: "Youtube Subscriptions",
                        
                      },
                      {
                        imageSrc: APIsImage,
                        subText: "Easy to Use API to build your custom apps for live and recorded video streaming.",
                        title: "APIs",
                        
                      },
                      {
                        imageSrc: DynamicContentImage,
                        subText: "Curate your Videos & PDFs for ready reckoners and hands on reference resources for teams.",
                        title: "Dynamic Content Resources",
                        
                      }
                    ]}
                    isAnimate = {true}
                  />
                </WhyChooseWrap>*/}
        <PreFooter fromPage="Home Page"/>
      </AnimationRevealPage>
      <Footer />
   </>
  );
}
