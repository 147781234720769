import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import ReduseCost from "images/costcut_ic.png";
import ShieldIconImage from "images/experience_ic.png";
import AgileTech from "images/agile_ic.png";
import Developer from "images/developer_ic.png";
import ReliableIconImage from "images/reliable-icon.svg";
import RiskIcon from "images/risk_ic.png";
import ReliableIcon from "images/reliable.png";
import TargetIcon from "images/target_ic.png";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-10 md:py-10`}
`;
const Subheading = tw(SubheadingBase)`tracking-widest mb-4 text-textBlack text-base`;
const Heading = tw.h2`xs:text-center w-full text-black`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-2 px-1 py-8`}
  .imageContainer {
    ${tw`text-center flex-shrink-0`}
    img {
      ${tw`w-20 h-20`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-xl leading-none text-black`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-400 text-base leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "", subheading="", description = "" }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "EXPERIENCE",
      description: "After spending many years putting our skills to use, we know what works best when building apps."
    },
    { imageSrc: ReduseCost, title: "REDUCE COST", description: "Our optimized process saves time and resource effort, thereby, reducing app development costs." },
    { imageSrc: AgileTech, title: "WE'RE AGILE", description: `"We're Agile." More than just a tagline, it's a philosophy that permeates everything we do.` },
    { imageSrc: Developer, title: "EXCEPTIONAL DEVELOPERS", description: "We choose the industry's best resources so that we deliver top quality apps to our customers." },
    { imageSrc: ReliableIcon, title: "Reliable", description: "We implement technologies that are in their ascendancies, giving you every chance of success" },
    { imageSrc: TargetIcon, title: "WE STAND BY YOU", description: "We are your custodians when it comes to building and nurturing your app from scratch." }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        {subheading && <Subheading>{subheading}</Subheading>}
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
