import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import DevelopmentProcess from "../features/TwoColWithVerticalImageMarketing.js";

const DevelopmentProcessSection = tw.div`bg-baseGray lg:-mt-12`;
const Subheading = tw.p`tracking-widest text-textBlack text-center`;

export default ({
  
}) => {
  return (
    <DevelopmentProcessSection>
      <DevelopmentProcess
        subheading={<Subheading>Our proprietary 5-step branding process designed to create a resilient brand for the future.</Subheading>}

        heading={
          <>
            How do we Do It
          </>
        }
      />
    </DevelopmentProcessSection>
  );
};
