import React from 'react'
import { Helmet } from 'react-helmet';
import tw from "twin.macro";
import Hero from "components/hero/AurumHeroContent.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import DevelopmentProcess from "components/development-process/";
import PreFooter from "components/footers/PreFooterBrand.js";
import generativeAIHeroImage from 'images/generative-ai-hero-image.svg'
import generativeAI from 'images/generative-ai.svg'
import Footer from "components/footers/SimpleFiveColumn.js";

const GenerativeAI = () => {
    const Subheading = tw.p`tracking-widest text-textBlack text-center`;
    const HighlightedText = tw.span`text-primary-500`;
    const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
    const TITLE = 'Generative AI';
    const tagNameD = `Unlock the power of generative AI services with our Ei solutions  for creative content, text generation and more. Explore innovative AI tools and solutions for your projects.`;

    return (
        <>
            <Helmet
                title={TITLE}
                meta={[
                    {
                        name: 'description',
                        content: { tagNameD },
                    },
                    {
                        property: `og:title`,
                        content: { TITLE },
                    },
                    {
                        property: `og:description`,
                        content: { tagNameD },
                    },
                    {
                        name: 'keywords',
                        content: 'AI content creation, AI graphics design, AI code generation, Creative AI, AI technology, Artificial intelligence, Text generation',
                    }
                ]}
            />
            <Hero roundedHeaderButton={true}
                mainTitle="Generative AI"
                subTitle={tagNameD}
                buttonText="Lets Go"
                buttonUrl="https://wat.me/919971200884/?text=Hi, We would like develop our App"
                imageSrc={generativeAIHeroImage}
                showClient={true}
            />
            <AnimationRevealPage>
                <MainFeature
                    primaryButtonText="Lets Talk"
                    primaryButtonUrl="https://wa.me/919971200884/?text=Hi, We would like to create an app"
                    heading="Unleash Creativity and Innovation with Generative AI Solutions."
                    description="<p>At EiSolutions, we harness the power of generative AI to drive innovation and unlock new possibilities for our clients. Our expertise lies in developing cutting-edge generative AI solutions tailored to your specific needs, whether it's creating unique designs, generating personalized content, or optimizing complex processes.</p><p></p><ul><li style='font-weight:bold'>Dynamic Content Generation</li><li style='font-weight:bold'>Personalized Recommendations</li><li style='font-weight:bold'>Creative Design Generation</li><li style='font-weight:bold'>Process Optimization</li><li style='font-weight:bold'>Automated Decision Making</li></ul>"
                    subheading=""
                    textOnLeft={false}
                    imageSrc={generativeAI}
                    imageBorder={false}
                    imageDecoratorBlob={false}
                />
                <DevelopmentProcess />
                <PreFooter fromPage="Customised Apps" formheading="Building Apps with Brand Identity Amplifies User Engagement & Conversion" formsubheading="Book Our Free Discussion Session Today - " heightlightText="Significance of Brand Identity and User Engagement in Online Business." />
            </AnimationRevealPage>
            <Footer />
        </>
    )
}

export default GenerativeAI