import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import ReduseCost from "images/analysis.png";
import DevelopmentProcess from "images/development-process.png";
import ShieldIconImage from "images/experience_ic.png";
import AgileTech from "images/agile_ic.png";
import UXDeveloper from "images/ux.png";
import UIDeveloper from "images/UI.png";
import BackendDeveloper from "images/backend.png";
import frontendDeveloper from "images/frontend.png";
import ProjectScope from "images/developer.png";
import QADeveloper from "images/qa.png";
import testing from "images/testing.png";
import launch from "images/launch.png";
import maintainace from "images/maintainace.png";
import Developer from "images/developer_ic.png";
import ReliableIconImage from "images/reliable-icon.svg";
import RiskIcon from "images/risk_ic.png";
import TargetIcon from "images/target_ic.png";

const Container = tw.div`relative`;

const TwoColumnContainer = styled.div`
  ${tw`flex flex-row items-center md:items-stretch md:flex-row lg:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-10 md:py-10`}
`;
const Subheading = tw(SubheadingBase)`tracking-widest mb-4 text-textBlack text-base`;
const Heading = tw.h2`xs:text-center w-full text-black`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`w-full` 

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Process = styled.div`
  .process{
    ${tw`flex flex-row  items-start px-4`}
    .image-container{
      ${tw`lg:w-80 lg:h-80 hidden lg:block`}
      .image-container img{
        ${tw`w-full h-full`}
      }
    }


  }
  .development-process ul li{
    ${tw`inline-block align-top  w-full mb-10 lg:w-1/2  lg:px-10`}
    .imageContainer{
      ${tw`w-20 h-20`}
       .imageContainer img{
        ${tw`w-20 h-20`}
      }
    }
    .textContainer {
      ${tw`mt-6`}
      .title {
        ${tw`mt-4 tracking-wide font-bold text-xl leading-none text-black`}
      }
      .description {
        ${tw`mt-1 sm:mt-4 font-medium text-secondary-400 text-base leading-loose mr-4`}
      }
    }
  }

`;



const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-2 px-1 py-8 w-full`}
  .imageContainer {
    ${tw`text-center flex-shrink-0`}
    img {
      ${tw`w-20 h-20`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-xl leading-none text-black`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-400 text-base leading-loose`}
  }
`;



const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "", subheading="", description = "" }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    // {
    //   imageSrc: DevelopmentProcess,
    //   title: "EXPERIENCE",
    //   description: "After spending many years putting our skills to use, we know what works best when building apps."
    // },
    { imageSrc: ReduseCost, title: "Technical Analysis", description: "A business analyst evaluates the client's requirement in detail and prepares a detailed technical execution plan." },
    { imageSrc: ProjectScope, title: "Project Scope", description: `"After a technical analysis, a detailed implementation is prepared, which is also known as developer copy, to initialize the UX and prototyping.` },
    { imageSrc: UXDeveloper, title: "UX", description: "Design are curated that provide meaningful and relevant experiences to users. This involves the design of the entire process of acquiring and integrating the product, including aspects of branding, design, usability and function." },
    { imageSrc: UIDeveloper, title: "UI Design & Prototyping", description: "At this stage, we prepare the product designs which will be reflected in the final stage during testing. All the elements and transitions are created and given to client for feedback," },
    { imageSrc: BackendDeveloper, title: "Backend Development", description: "Backend team developers codes the backend functionality to create the server side of the app. The server side allows the frontend to interact seamlessly with database. " },
    { imageSrc: frontendDeveloper, title: "Fronend Development", description: "Backend team developers codes the backend functionality to create the server side of the app. The server side allows the frontend to interact seamlessly with database. " },
    { imageSrc: QADeveloper, title: "QA", description: "Our Quality Assurance department carry out ent-to-end code testing to make sure the app is highly secured and free from bugs and is stable." },
    { imageSrc: testing, title: "UAT", description: "At this phase we carry out a user based testing where client and other users are invited to test the app for its functionality or any other issues that may arise before it goes out to public." },
    { imageSrc: launch, title: "Post Launch", description: "At this stage, we prepare landing pages, social media presence and an introductory video for initial release. We also assist in executing marketing activities." },
    { imageSrc: maintainace, title: "Support & Maintenance", description: "We provide complete support for the projects even after the launch by handling updates and new features. " },

  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <TwoColumnContainer>
        <Heading>{heading}</Heading>
        {subheading && <Subheading>{subheading}</Subheading>}
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        <Process>
          <div className="process">
            <div className="image-container">
              <img src={DevelopmentProcess} />
            </div>
            <div className="development-process">
              <ul>
                {cards.map((card, i) => (
                  <li>
                    <div className="imageContainer">
                        <img src={card.imageSrc || defaultCardImage} alt="" />
                    </div>
                    <div className="textContainer">
                      <span className="title">{card.title || "Fully Secure"}</span>
                      <p className="description">
                        {card.description}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Process>
      </TwoColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
