import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import logo from "images/logo.svg";

const Header = tw.header`
  flex justify-between items-center 
  w-full  lg:py-0 lg:pb-3 xs:pb-2 border-b-2  
`;
const HeadingRow = tw.div`lg:flex lg:ml-0 lg:px-56 xs:flex xs:px-10 xs:pt-10 xs:ml-8`;
const Heading = tw.p`lg:text-3xl font-bold text-gray-900 xs:text-2xl`;

export const NavLink = tw.a`
  lg:text-lg xs:text-lg sm:py-3
  font-semibold text-blue-500 tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:text-blue-700 hocus:text-blue-700
`;

export const PrimaryLink = tw(NavLink)`
  xs:mx-3
  lg:mx-0
  px-8 py-3 bg-secondary-600 text-gray-100
  hocus:bg-secondary-900 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;
const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! lg:ml-4 xs:ml-16`};

  img {
    ${tw`mr-3`}
  }
`;
const UrlSection = tw.div`lg:flex lg:flex-col lg:px-56 lg:py-10 xs:flex xs:flex-col xs:px-20 xs:py-10`;
const UrlHead = tw.h4`text-3xl font-semibold text-gray-900`;
const UrlList = tw.li`flex flex-col`;
const UrlItem = tw.li`mb-4`;
export default ({
  headingText = "EiSolutions Sitemap",
}) => {
  const [visible, setVisible] = useState(7);

  return (
    <AnimationRevealPage>
      <Header className="header-light">
        <LogoLink href="/">
          <img src={logo} alt="EiSolutions-logo" className="ei-logo" />
        </LogoLink>
      </Header>
      <Container>
        <HeadingRow>
          <Heading>{headingText}</Heading>
        </HeadingRow>
        <UrlSection>
          <UrlHead>
            <NavLink href="/about-us">About Us</NavLink>
          </UrlHead>
          <UrlHead>Services</UrlHead>
          <UrlList>
            <UrlItem><NavLink href="/mobile-apps">Mobile App Development</NavLink></UrlItem>
            <UrlItem><NavLink href="/website-development">Website Development</NavLink></UrlItem>
            <UrlItem><NavLink href="/ui-ux-design">Design (UI/UX/Graphic)</NavLink></UrlItem>
            <UrlItem><NavLink href="/brand-identity">Brand Identity</NavLink></UrlItem>
            <UrlItem><NavLink href="/enterprise-app-development">Enterprise App Development</NavLink></UrlItem>
            <UrlItem><NavLink href="/quality-assurance">Quality Assurance</NavLink></UrlItem>
            <UrlItem><NavLink href="/support-and-maintenance">Support and Maintenance</NavLink></UrlItem>
            <UrlItem><NavLink href="/digital-marketing">Digital Marketing</NavLink></UrlItem>
            <UrlItem><NavLink href="/generative-ai">Generative AI</NavLink></UrlItem>
          </UrlList>
        </UrlSection>
      </Container>
    </AnimationRevealPage>
  );
};

