import React from 'react'
import { Helmet } from 'react-helmet';
import tw from "twin.macro";
import Hero from "components/hero/AurumHeroContent.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import DevelopmentProcess from "components/development-process/";
import PreFooter from "components/footers/PreFooterBrand.js";
import brandIdentityHeroImage from 'images/brand-identity-hero-image.svg'
import brandIdentity from 'images/brand-identity.svg'
import Footer from "components/footers/SimpleFiveColumn.js";

export default () => {
    const Subheading = tw.p`tracking-widest text-textBlack text-center`;
    const HighlightedText = tw.span`text-primary-500`;
    const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
    const TITLE = 'Brand identity';
    const tagNameD = `Discover your unique brand identity with our expert services. From logo design, Brand identity to brand strategy, we help you stand out in the market.`;

    return (
        <>
            <Helmet
                title={TITLE}
                meta={[
                    {
                        name: 'description',
                        content: { tagNameD },
                    },
                    {
                        property: `og:title`,
                        content: { TITLE },
                    },
                    {
                        property: `og:description`,
                        content: { tagNameD },
                    },
                    {
                        name: 'keywords',
                        content: 'Brand Identity, Brand Guidelines, Brand Personality, Brand Storytelling, logo designing',
                    }
                ]}
            />
            <Hero roundedHeaderButton={true}
                mainTitle="Brand Identity"
                subTitle={tagNameD}
                buttonText="Lets Go"
                buttonUrl="https://wat.me/919971200884/?text=Hi, We would like develop our App"
                imageSrc={brandIdentityHeroImage}
                showClient={true}
            />
            <AnimationRevealPage>
                <MainFeature
                    primaryButtonText="Lets Talk"
                    primaryButtonUrl="https://wa.me/919971200884/?text=Hi, We would like to create an app"
                    heading="Craft Your Digital Identity with EiSolutions' Branding Expertise."
                    description="<p>Central to our methodology is the belief that brand and user experience (UX) are intricately linked and complement each other. At EiSolutions, we excel in crafting and showcasing digital brand personas across various platforms. Our team offers strategic guidance and directives to maintain coherence throughout your brand journey.</p><p></p><ul><li style='font-weight:bold'>Brand Strategy</li><li style='font-weight:bold'>Brand Architecture</li><li style='font-weight:bold'>Verbal Identity</li><li style='font-weight:bold'>Visual Identity</li><li style='font-weight:bold'>Brand Guidelines</li><li style='font-weight:bold'>Brand Experiences</li></ul>"
                    subheading=""
                    textOnLeft={false}
                    imageSrc={brandIdentity}
                    imageBorder={false}
                    imageDecoratorBlob={false}
                />

                <PreFooter fromPage="Customised Apps" formheading="Building Apps with Brand Identity Amplifies User Engagement & Conversion" formsubheading="Book Our Free Discussion Session Today - " heightlightText="Significance of Brand Identity and User Engagement in Online Business." />
            </AnimationRevealPage>
            <Footer />
        </>
    )
}