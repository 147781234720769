import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import MobileApp from "images/aurum_Icons/mobileApp.png";
import WebsiteDevelopment from "images/aurum_Icons/webdev.png";
import BrandIdentity from "images/aurum_Icons/brand_identity.png";
import IOTDevelopment from "images/aurum_Icons/IOT_dev.png";
import EnterpriseAppDevelopment from "images/aurum_Icons/enterprise_dev.png";
import AugmentedReality from "images/aurum_Icons/virtual_reality.png";
import TVAppDevelopment from "images/aurum_Icons/TV_dev.png";
import QualityAssurance from "images/aurum_Icons/quality.png";
import SupportMaintenance from "images/aurum_Icons/support.png";
import agilityImage from "images/aurum_Icons/agilityImage.svg";
import unifiedLibrary from "images/aurum_Icons/unifiedLibrary.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";
import { components } from "ComponentRenderer.js";


const Container = tw.section`relative w-full`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-10 md:py-20`}
`;
const Link = tw.a``;
const Subheading = tw(SubheadingBase)`tracking-widest mb-4 text-textBlack text-base`;
const Heading = tw.h2`xs:text-center w-full text-black`;
const Description = tw(SectionDescription)`w-full text-center`;
const HighlightedText = tw.span`text-primary-500`;


const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`text-center sm:text-center mx-2 px-0 py-3 bg-white mt-4`}
  .imageContainer {
    ${tw`text-center p-4 h-24`}
    img {
      ${tw`w-20 mx-auto `}
    }
  }

  .textContainer {
    ${tw`xs:ml-0 sm:mx-1 mt-4 sm:mt-2 text-center px-1 h-48`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-lg leading-tight`}
    &:after{
      content: "";
      ${tw`bg-brand-100 block w-[100px] h-[2px] mx-auto mt-2`}
    }
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-400 text-base leading-loose`}
  }
  .description p{
    ${tw`mt-5`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "", subheading = "", description = "", innerPages = components.innerPages }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      id: 1,
      imageSrc: MobileApp,
      title: "Mobile App Development",
      url: innerPages.mobileapps.url,
      description: "We deliver mobile apps that are truly advanced and let your brand stand out. "
    },
    {
      id: 2,
      imageSrc: WebsiteDevelopment,
      title: "Website Development",
      url: innerPages.websitedevelopement.url,
      description: "We deliver awesome web experiences that are truly advanced and let your brand stand out."
    },
    {
      id: 3,
      imageSrc: BrandIdentity,
      title: "UI/UX",
      url: innerPages.uiuxdesign.url,
      description: "With studying your brand attributes, we cast your consistent message all over."
    },
    {
      id: 4,
      imageSrc: IOTDevelopment,
      title: "Brand Identity",
      url: innerPages.brandidentity.url,
      description: "We explore & recommend solutions in exciting world of IOT related technology. "
    },
    {
      id: 5,
      imageSrc: EnterpriseAppDevelopment,
      title: "Enterprise App Development",
      url: innerPages.enterpriseappdevelopment.url,
      description: "Our exhaustive procedure and capacity to build apps makes us a top choice."
    },
    {
      id: 6,
      imageSrc: QualityAssurance,
      title: "Quality Assurance",
      url: innerPages.qualityassurance.url,
      description: "We ensure a clean & glitch-free experience thanks to our diligent QA process."
    },
    {
      id: 7,
      imageSrc: SupportMaintenance,
      title: "Support & Maintenance",
      url: innerPages.supportandmaintenance.url,
      description: "As part of branding touch points, we pride ourselves on delivering superior experiences."
    },
    {
      id: 8,
      imageSrc: AugmentedReality,
      title: "Digital Marketing",
      url: innerPages.digitalmarketing.url,
      description: "Technology in its ascendancy promises a thrilling experience & opportunity for your brand."
    },
    {
      id: 9,
      imageSrc: TVAppDevelopment,
      title: "Generative AI",
      url: innerPages.generativeai.url,
      description: "Learning state-of-art tools for deploying over TV apps to Appstore & Playstore. "
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        {subheading && <Subheading>{subheading}</Subheading>}
        {description && <Description>{description}</Description>}
        {cards.map((card) => (
          <Column key={card.id}>
            <Link href={card.url} title={card.title}>
              <Card>
                <div className="imageContainer">
                  <img src={card.imageSrc || defaultCardImage} alt="" />
                </div>
                <div className="textContainer">
                  <span className="title">{card.title || "Fully Secure"}</span>
                  <div className="description" dangerouslySetInnerHTML={{ __html: card.description }}></div>
                </div>
              </Card>
            </Link>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
