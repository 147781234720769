import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { components } from "ComponentRenderer.js";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import {serverUtilities} from '../../services/serverutilities.service';

import logo from "../../images/logo.svg";
// import VideoLogo from "../../images/diwali_logo_anim.mp4"
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as CaretIcon } from "../../images/aurum_Icons/carat.svg";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto lg:py-5
`;


export const NavBar = styled.ul`
  z-index:99;
  .caret{
    transform:roate(90deg);
  }

`;

export const NavLinks = styled.li`
    ${tw`inline-block lg:mx-6`} 
    @media only screen and (min-width:1024px){
      &:hover .submenu{
        ${tw`block absolute`}
        cursor:pointer
      }  
      a.active{
        ${tw`border-brand-100 text-brand-100`}
      }
    }
    > span{
      ${tw`text-lg my-2 lg:text-sm lg:mx-0 lg:my-0 flex justify-between
        font-semibold text-black tracking-wide transition duration-300
        pb-1 lg:border-b-2 border-transparent hover:border-brand-100 hocus:text-brand-100`
      }
    } 
    .submenu{
      box-shadow:0 0 10px 0 rgb(0 0 0 / 20%);
      width:236px;
      margin-top:-3px;
      ${tw`bg-white lg:rounded lg:border-t-4 border-brand-100 lg:hidden`}
      a{
        ${tw`block py-2 px-4 mr-0 ml-0 border-b-0`}
      }
    }
    span {
      .caret{
        transform:rotate(90deg);
        display:inline-block; 
        transition: 0.2s;
      }
      &:hover .caret{
        transition: 0.2s;
        transform:rotate(-90deg);
        path{
          fill: #E28302
        }
      }
    }
`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-0 lg:my-0
  font-semibold text-black tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-brand-100 hocus:text-brand-100
`;

export const PrimaryLink = tw(NavLink)`
  xs:mx-3
  lg:mx-0
  px-8 py-3 bg-brand-100 text-gray-100
  hocus:bg-brand-100 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`mr-3`}
  }
`;

export const MobileNavLinksContainer = styled.nav`
  ${tw`flex flex-1 items-center justify-between`}
  ${LogoLink}{
    ${tw`z-20`}
  }
`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-gray-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 left-0 right-0 bottom-0 inset-x-0 mx-0 my-0 px-0 py-20 text-white bg-blackStep-200`}
  ${NavLinks} {
    ${tw`flex flex-col border-b-2 border-white border-opacity-5 px-0`}
    &:last-child{
      ${tw`border-b-0`}
    }
    > span, a{
      ${tw`text-white px-3`}
      path{
        fill: #ffffff
      }
    }
    .submenu{
      transition: height ease 0.2s;
      ${tw`h-0 opacity-0 w-full hidden`}
      &.active{
        ${tw`h-auto opacity-100 block`}
      }
      a{
        ${tw`text-black`}
      }
      ${NavLink}{
        ${tw`text-sm`}

      }
    }
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({ 
  roundedHeaderButton = false, 
  logoLink, 
  links, 
  className, 
  collapseBreakpointClass = "lg",
  innerPages = components.innerPages, 
}) => {

  const [offset, setOffset] = useState(0);
  const [headerSticky, setHeader] = useState(null);
  const [isActive, setIsActive] = useState(false);
  
  useEffect(() => {
    window.onscroll = () => {
      if(offset > 0 || offset < window.pageYOffset)
      setOffset(window.pageYOffset)
    }
  }, []);

  const handleToggle = (index) => {
    console.log(index);
    if(isActive === index) {
      return setIsActive("0")
    }
    setIsActive(index)
  };

  const handlePaynow = ()=>{
    serverUtilities.dopaymentnow().then(data=>{
      console.log("payment status");
    }, error=>{
      console.log(error);
    })
  }
  // console.log(window.pageYOffset);
  // console.log(offset)
  
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */

  // <CaretIcon tw="w-6 h-6" className="caret" />
  const defaultLinks = [
    <NavBar>
      
    
      <NavLinks key={1} onClick={() => handleToggle(1)}>
        <NavLink className={(window.location.href.indexOf(innerPages.aboutus.url) > -1) ? 'active' : ''} href={innerPages.aboutus.url}>About</NavLink>
      </NavLinks>
      {/*<NavLinks key={2} onClick={() => handleToggle(2)}>
        <span>Solutions </span>
        <div className={(isActive === 2) ? 'submenu active' : 'submenu'}>
          <NavLink href={innerPages.livevideostreaming.url}>Live Video Streaming</NavLink>
          <NavLink href={innerPages.videoondemand.url}>Recorded Video Streaming</NavLink>
          <NavLink href={innerPages.customisedapps.url}>Customised Apps</NavLink>
          <NavLink href={innerPages.videostreamingapi.url}>REST APIs</NavLink>
          <NavLink href={innerPages.securedvideostreaming.url}>Secured Video Streaming</NavLink>
        </div>
      </NavLinks>*/}
      <NavLinks key={2} onClick={() => handleToggle(2)}>
        <span>Services </span>
        <div className={(isActive === 2) ? 'submenu active' : 'submenu'}>
          <NavLink href={innerPages.mobileapps.url}>Mobile App Development</NavLink>
          <NavLink href={innerPages.websitedevelopement.url}>Website Development</NavLink>
          <NavLink href={innerPages.uiuxdesign.url}>Design (UI/UX/Graphic)</NavLink>
          <NavLink href={innerPages.brandidentity.url}>Brand Identity</NavLink>
          <NavLink href={innerPages.enterpriseappdevelopment.url}>Enterprise App Development</NavLink>
          <NavLink href={innerPages.qualityassurance.url}>Quality Assurance</NavLink>
          <NavLink href={innerPages.supportandmaintenance.url}>Support And Maintenance</NavLink>
          <NavLink href={innerPages.digitalmarketing.url}>Digital Marketing</NavLink>
          <NavLink href={innerPages.generativeai.url}>Generative AI</NavLink>
        </div>
      </NavLinks>
      {/*<NavLinks key={4} onClick={() => handleToggle(4)}>
        <span>Work</span>
        <div className={(isActive === 4) ? 'submenu active' : 'submenu'}>
          <NavLink href={innerPages.whyaurumlabs.url}>Why Aurum</NavLink>
          <NavLink href={innerPages.contactus.url}>Contact Us</NavLink>
          <NavLink href={innerPages.media.url}>Media</NavLink>
        </div>
      </NavLinks>*/}
      
      <NavLinks tw="">
        {/*<NavLink href={innerPages.login.url} tw="lg:ml-0! lg:mr-6">
          Login
        </NavLink>*/}
        <PrimaryLink css={roundedHeaderButton && tw`rounded-full`} className="bt-round" href={innerPages.contactus.url}>Contact Us</PrimaryLink>
      </NavLinks>
      {/* <NavLinks key={2} onClick={() => handlePaynow()} tw="cursor-pointer">Pay Now</NavLinks> */}
    </NavBar>
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" className="ei-logo" />
      {/* <video width="280" autoPlay muted loop>
        <source src={VideoLogo} type="video/mp4"/>
        Your browser does not support the video tag.
      </video> */}
    </LogoLink>
  );
  

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light aurum-navbar"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
        
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {links}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
