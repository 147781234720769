import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import DevelopmentProcess from "../features/TwoColWithVerticalImage.js";

const DevelopmentProcessSection = tw.div`bg-baseGray `;
const Subheading = tw.p`tracking-widest text-textBlack text-center`;

export default ({
  
}) => {
  return (
    <DevelopmentProcessSection>
      <DevelopmentProcess
        subheading={<Subheading>What are processes involved in the website development process?</Subheading>}

        heading={
          <>
            Development Process
          </>
        }
      />
    </DevelopmentProcessSection>
  );
};
