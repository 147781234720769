import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import DigitalImage from "images/aurum_Icons/desktop1.svg";
import ApplicationImage from "images/aurum_Icons/application.svg";
import DesignUI from "images/aurum_Icons/design1.svg";
import Mobile from "images/aurum_Icons/mobile1.svg";
import BlockChain from "images/aurum_Icons/blockchain.svg";
import MachineLearning from "images/aurum_Icons/machine.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.section`relative bg-baseGray w-full`;

const ThreeColumnContainer = styled.div`
  ${tw`xs:px-10 sm:px-0 flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-10 md:py-20`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-textBlack text-base`;
const Heading = tw.h2`xs:text-center w-full text-black`;
const Description = tw(SectionDescription)`w-full text-center`;
const HighlightedText = tw.span`text-primary-500`;


const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm my-6 relative rounded`}
`;


const Title = styled.h1`
  ${tw`mt-4 tracking-wide font-bold text-lg leading-none h-10 text-black opacity-100`}
`;


const Card = styled.div`
  ${tw`text-center sm:text-left h-full mx-2 px-0 py-2 border rounded relative`}


  .services{
    ${tw`!mb-8`}
  }
  .imageContainer {
    ${tw`text-center p-4 h-48` }
    img {
      ${tw`w-64  mx-auto`}
    }
  }

  .textContainer {
    ${tw`xs:ml-0 sm:ml-5 mt-4 pt-20 sm:mt-2 text-left px-2 h-96`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-lg leading-none `}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-400 text-base leading-loose`}
  }
  .description p{
    ${tw`mt-5`}
  }

  .framework{
    ${tw`p-4`}
      p{
      ${tw`text-center text-xs font-semibold pt-8 capitalize border-t-2 border-gray-400 text-black`}
    }
  }

`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "", subheading = "", description = "" }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: DigitalImage,
      title: "Desktop Application Development",
      description: "Need a desktop based application, we can develop a platform independent desktop application that gives similar experience on Linux Mac & Windows",
      framework:"ANGULAR | REACTJS | JAVA"
    },
    { imageSrc: ApplicationImage, 
      title: "Web Application Development",
      description: "Aurum truly understands the challenges associated with custom web application development. We provide solutions in multiple delivery models based on our client's requirement.</p>",
      framework:"PHP/MYSQL | CMS | YII2 | ANGULAR" 
    },
    { imageSrc: DesignUI, 
      title: "Web Design Services",
      description: "We understand that UI/UX is one of the most important factor for acquiring and retaining the user. Aurum creates custom web design based on our your needs, but at the same time</p>",
      framework:"UI/UX | HTML5 | CSS3 | JAVASCRIPT" 
    },
    { imageSrc: Mobile, 
      title: "Mobile Development Services",
      description: "Mobile as an application platform has become very important. Irrespective of functional vertical, accessibility of application on Mobile platform is almost mandatory.",
      framework:"IOS | ANDROID | PHONEGAP | IONIC" 
    },
    { imageSrc: BlockChain, 
      title: "Blockchain Development",
      description: "Looking to get decentralised blockchain application created for your domain? Let us know. we can help.",
      framework:"SEO | SMO | EMAIL MARKETING" 
    },
    { imageSrc: MachineLearning, 
      title: "Machine Learning Services (Beta)",
      description: "From image recognition to Chatbot creation, we are working on it all! Let us know the unique problem you are trying to solve and we will help you do it! However, if we cannot, we will be upfront and direct as well!",
      framework:"NLP | IMAGE PROCESSING | DATA ANALYTICS" 
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer >
        <Heading>WHAT WE DO?</Heading>
        {subheading && <Subheading>Aurum Labs is a software solutions company providing services across the board in technical and functional domains.</Subheading>}
        {description && <Description>{description}</Description>}
        {cards.map((card, i) => (
          <Column key={i} >
            <Card className="CardShadow">
              <div className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </div>
              <div className="textContainer">
                <Title>{card.title || "Fully Secure"}</Title>
                <div className="description" dangerouslySetInnerHTML={{ __html:card.description}}></div>
              </div>
              <div className="">
                <div className="framework"><p>{card.framework}</p></div>
              </div>
            </Card>
            
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
